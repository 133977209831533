import { ConsumerTypeEnum } from "@/pages/consumerTrustIndex/interface/consumer";
import { authSelector } from "@/redux/slice/authSlice";
import moment from "moment";
import { createContext } from "react";
import { useSelector } from "react-redux";

// Utils
export const userContext = createContext();

const UserContextProvider = (props) => {
  const userDetails = useSelector(authSelector);

  const checkConsentAuthorisation = () => {
    if (
      userDetails?.consumer_type === ConsumerTypeEnum.PRODUCTION &&
      userDetails?.accounts?.consent_status === "ACTIVE" &&
      moment(userDetails?.accounts?.request_timestamp).isAfter(moment())
    ) {
      return true;
    } else if (
      userDetails?.consumer_type === ConsumerTypeEnum.SANDBOX &&
      userDetails?.consent_status === "ACTIVE" &&
      moment(userDetails?.request_timestamp).isAfter(moment())
    ) {
      return true;
    }
    return false;
  };

  return (
    <userContext.Provider
      value={{
        userDetails,
        checkConsentAuthorisation,
      }}
    >
      {props.children}
    </userContext.Provider>
  );
};

export default UserContextProvider;
