const SiteFooter = ({ classname = "" }: { classname?: string }) => {
  return (
    <footer
      className={`bottom-0 border-t border-grey-stroke-primary w-full text-grey-content-tertiary text-sm py-2 bg-white h-[55px] lg:h-[35px] ${classname}`}
    >
      <div className="w-3/6 lg:w-3/4 xl:w-3/6 mx-auto flex flex-col lg:flex-row justify-between items-center px-4 text-center md:text-left">
        <p>©Trudenty 2025. All rights reserved.</p>
        <div className="space-x-4 mt-2 md:mt-0">
          <a href="https://trudenty.com/privacy-policy" target="_blank">
            Privacy policy
          </a>
          <a href="https://trudenty.com/terms-of-service" target="_blank">
            Terms of use
          </a>
          <a href="mailto:support@trudenty.com" target="_blank">
            Contact support
          </a>
        </div>
      </div>
    </footer>
  );
};
export default SiteFooter;
