import CTIDataSharing from "@/assets/images/cti-data-sharing.png";
import { Button } from "@/components/button";
import InputField from "@/components/formElement/InputField";
import ReactListbox from "@/components/formElement/ReactListbox";
import SiteFooter from "@/components/siteFooter";
import SiteHeader from "@/components/siteHeader";
import { useAxiosGet, useAxiosPost } from "@/hooks/useAxios";
import {
  promptDisabledSelector,
  setPromptOpen,
} from "@/redux/slice/promptSlice";
import { setToken } from "@/redux/slice/tokenSlice";
import { isSubmittable } from "@/utils/helpers";
import { ReactComponent as GuideIcon } from "@assets/icons/guideIcon.svg";
import { ReactComponent as CloseIcon } from "@icons/close-white.svg";
import { Form, Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { ConsumerTypeEnum } from "../consumerTrustIndex/interface/consumer";
import { ConsumerInitializeSchema } from "./validations";

interface ConsumerInitialize {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  loyality_Id: string | null;
  projectApiKey: string;
}

const ConsumerTypes = [
  {
    title: "Sandbox Consumer Data",
    value: ConsumerTypeEnum.SANDBOX,
  },
  {
    title: "Mastercard Sandbox-As-A-Service",
    value: ConsumerTypeEnum.MASTERCARD,
  },
  // {
  //   title: "Worldline Open Banking Sandbox",
  //   value: ConsumerTypeEnum.WORLDLINE,
  // },
  {
    title: "Live Open Banking Data",
    value: ConsumerTypeEnum.LIVE,
  },
];
const initialValues: ConsumerInitialize = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: null,
  loyality_Id: null,
  projectApiKey: "",
};

const Guide = () => {
  const dispatch = useDispatch();
  const { projectApiKey } = useParams();
  const location = useLocation();
  const splitLocation =
    location.pathname.split("/")?.[2] ?? location.pathname.split("/")?.[1];
  const isPromptDisabled = useSelector(promptDisabledSelector);
  const [isError, setIsError] = useState<string>("");
  const [consumerInitializeApi] = useAxiosPost();
  const [getDefaultProjectApi] = useAxiosGet();
  const [consumerType, setConsumerType] = useState(ConsumerTypeEnum.SANDBOX);
  const [defaultSandboxProjectApiKey, setDefaultSandboxProjectApiKey] =
    useState<string | null>(null);
  const [defaultRealProjectApiKey, setDefaultRealProjectApiKey] = useState<
    string | null
  >(null);
  const [initialConsumer, setInitialConsumer] =
    useState<ConsumerInitialize>(initialValues);

  useEffect(() => {
    if (!isPromptDisabled) {
      dispatch(setPromptOpen({ isOpen: true }));
    }
  }, []);

  const onSubmit = async (values: FormikValues) => {
    const data = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      ...(values?.phoneNumber && { mobile_number: values?.phoneNumber }),
      ...(values?.loyality_Id && { loyalty_number: values?.loyality_Id }),
      api_key: values?.projectApiKey,
      consumer_type: consumerType,
    };
    const response = await consumerInitializeApi("/consumers/initialize", data);
    if (response?.data?.access_token) {
      dispatch(setToken({ access_token: response?.data?.access_token }));
    } else {
      setIsError(response?.error);
    }
  };

  useEffect(() => {
    const fetchDefaultProject = async () => {
      const response = await getDefaultProjectApi(
        `/consumers/get-default-project${consumerType === ConsumerTypeEnum.SANDBOX ? "?consumer_type=SANDBOX" : ""}`
      );
      if (response?.data) {
        if (consumerType === "LIVE")
          setDefaultRealProjectApiKey(response.data.api_key);
        else {
          setDefaultSandboxProjectApiKey(response.data.api_key);
        }
        setInitialConsumer({
          ...initialConsumer,
          projectApiKey: response.data.api_key,
        });
      }
    };
    if (splitLocation === "request-open-banking-consent") {
      if (consumerType === "LIVE") {
        if (!defaultRealProjectApiKey) fetchDefaultProject();
        else
          setInitialConsumer({
            ...initialConsumer,
            projectApiKey: defaultRealProjectApiKey,
          });
      } else {
        if (!defaultSandboxProjectApiKey) fetchDefaultProject();
        else
          setInitialConsumer({
            ...initialConsumer,
            phoneNumber: "",
            loyality_Id: "",
            projectApiKey: defaultSandboxProjectApiKey,
          });
      }
    } else if (splitLocation === "sandbox" && projectApiKey) {
      setInitialConsumer({
        ...initialConsumer,
        projectApiKey: `sandbox_${projectApiKey}`,
      });
    }
  }, [consumerType]);

  const ConsumerTypeMessage = (consumer_type: string) => {
    if (consumer_type === "LIVE") {
      return "Enter your full name and email to get started with calculating your own Consumer Trust Index.";
    } else
      return (
        <>
          <h4 className="text-grey-content-secondary text-lg font-semibold">
            Choose a Test Consumer
          </h4>
          This data source has several consumers available for testing. You can
          find their details{" "}
          <Link
            to={
              [ConsumerTypeEnum.MASTERCARD, ConsumerTypeEnum.SANDBOX].includes(
                consumer_type as ConsumerTypeEnum
              )
                ? "https://hubs.ly/Q032gHf80"
                : "https://trudenty.notion.site/Consumer-Trust-Network-Sandbox-Guide-174a0212ddd080d4b593d478ac5f06d2?pvs=97#174a0212ddd0808bb08ac5c3e1b4a179"
            }
            target="_blank"
            className="underline"
          >
            here.
          </Link>
          {consumer_type === ConsumerTypeEnum.MASTERCARD && (
            <>
              <br /> Alternatively, you can insert the consumer data as provided
              in your Mastercard Sandbox-As-A-Service Bankbot
            </>
          )}
        </>
      );
  };

  return (
    <div className="w-full">
      <SiteHeader />
      <div className="w-full flex justify-center">
        <div className={`lg:w-3/4 xl:w-3/6 w-3/6 mt-6`}>
          {isError && (
            <div className="gap-4 mb-4">
              <div>
                <span
                  className={`text-body-black font-medium bg-red-alert-100 mt-1 flex gap-3 p-4`}
                >
                  <CloseIcon className="border-transparent rounded-xl bg-red-alert w-[18px] h-[18px] text-white p-[4px]" />
                  Sorry, this consumer data does not match any of the details
                  provided in our documentation. Please try again with any of
                  the provided consumer credentials.
                </span>
              </div>
            </div>
          )}

          <div className="">
            <div className="border border-grey-stroke-primary bg-white2 p-4 gap-6 flex flex-col mb-6 rounded-lg">
              <img src={CTIDataSharing} className="object-cover" alt="" />
              <div>
                <h2 className="text-trud-black text-3xl font-medium leading-8 mb-2.5">
                  Request Open Banking Consent
                </h2>
                <p className="text-grey-content-secondary font-normal text-[17.5px] leading-[21px] tracking-[-0.2px]">
                  You can initialise consumers on the network by requesting
                  their Open Banking consent, allowing secure, permitted access
                  to financial data for more accurate Consumer Trust Index (CTI)
                  calculation and fraud risk assessment.
                  <br />
                  <br />
                  For demonstration purposes, the CTI can be calculated in our
                  sandbox using data from various sources, including Trudenty's
                  Sandbox, Mastercard, and live Open Banking (UK banks only).
                  While API access isn’t available in the sandbox, this mirrors
                  the real-world process of obtaining Open Banking consent and
                  leveraging financial data for Consumer Trust Index scoring.
                  <br />
                  <br />
                  Below is a simulation of what happens automatically behind the
                  scenes when using our API. To get started, select a data
                  source and manually input the consumer data into the available
                  fields. Once the CTI is generated, you can explore detailed
                  insights into how the system calculates it.
                  <br />
                  <br />
                  CTI scores can be viewed in the Network Console for human-led
                  decisioning or retrieved via API in a live setup for automated
                  decisioning.
                </p>
              </div>
            </div>

            <p className="text-base font-semibold text-desc-grey pb-2.5 leading-7">
              Select an Open Banking data source
            </p>
            <ReactListbox
              optionsClass="min-w-full"
              btnClass="w-full rounded-none h-12 !text-body-black border border-stroke bg-white4 mb-4"
              Options={
                ConsumerTypes?.map((type) => ({
                  label: type.title,
                  value: type.value,
                })) ?? []
              }
              selectedOption={consumerType}
              labelClass="text-grey-content-secondary"
              onChange={(selectedOption) => {
                setConsumerType(selectedOption);
              }}
            />
            <div
              className={`p-4 flex bg-grey-650 border border-stroke-primary rounded-lg mb-4 gap-2 mt-4 text-grey-content-secondary ${consumerType === "LIVE" ? "items-center" : ""}`}
            >
              <div>
                <GuideIcon />
              </div>
              <span className="font-medium text-[17.5px] leading-[21.6px]">
                {ConsumerTypeMessage(consumerType)}
              </span>
            </div>
            <div className={`px-4 mt-5 `}>
              <Formik
                initialValues={initialConsumer}
                enableReinitialize={true}
                validationSchema={ConsumerInitializeSchema}
                onSubmit={onSubmit}
              >
                {({ values }) => {
                  return (
                    <Form>
                      <div className="grid grid-cols-12 gap-8">
                        <div className="col-span-12 md:col-span-6">
                          <InputField
                            label="First name"
                            type="text"
                            name="firstName"
                            placeholder="Enter First Name"
                            className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                            isCompulsory={true}
                          />
                        </div>
                        <div className=" col-span-12 md:col-span-6">
                          <InputField
                            label="Last name"
                            type="text"
                            name="lastName"
                            placeholder="Enter Last Name"
                            className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                            isCompulsory={true}
                          />
                        </div>
                        <div className={` col-span-12 md:col-span-12`}>
                          <InputField
                            label="Email"
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                            className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                            isCompulsory={true}
                          />
                        </div>

                        <div className="col-span-12">
                          <InputField
                            label="Project API key"
                            type="text"
                            value={values?.projectApiKey}
                            name="projectApiKey"
                            placeholder="Enter Project API key"
                            className="rounded-lg !bg-white border border-grey-content-quaternary text-body-black"
                            isCompulsory={true}
                          />
                          <p className="text-grey-content-tertiary mt-3 font-medium">
                            An API key for a demo project has been provided by
                            default. To test your own projects, insert the
                            relevant project API key from the console.
                          </p>
                        </div>
                      </div>
                      <div className="mt-8">
                        <Button
                          title="Submit"
                          color="dark3"
                          category="3"
                          type="submit"
                          className="rounded-[4rem] mb-3 px-5 py-3 text-base text-left !w-auto inline-block font-medium "
                          disabled={
                            !isSubmittable(
                              values,
                              {
                                firstName: "",
                                lastName: "",
                                email: "",
                                projectApiKey: "",
                              },
                              [
                                "firstName",
                                "lastName",
                                "email",
                                "projectApiKey",
                              ]
                            )
                          }
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <SiteFooter classname="absolute 2xl:bottom-auto max-2xl:bottom-auto" />
    </div>
  );
};

export default Guide;
