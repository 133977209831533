import { authSelector } from "@/redux/slice/authSlice";
import { clearConsumerData } from "@/redux/slice/consumerSlice";
import { setPromptDisabled } from "@/redux/slice/promptSlice";
import { clearToken } from "@/redux/slice/tokenSlice";
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";
import { useDispatch, useSelector } from "react-redux";
import ScoreBubble from "../../pages/consumerTrustIndex/components/ScoreBubble";
import BackArrow from "../BackArrow";
import Meta from "../Meta";
import ReactDropDown from "../formElement/ReactDropdown";

const SiteHeader = ({
  header,
  showBackButton = false,
  isDisableCTI = false,
}: {
  header?: string;
  showBackButton?: boolean;
  isDisableCTI?: boolean;
}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(authSelector);

  return (
    <div
      className={`flex items-center w-full py-4 px-9 border-b border-grey-stroke-primary ${isDisableCTI ? "" : ""}`}
    >
      <Meta
        title={`${header ? header + " - " : ""}Trudenty Trust Network Console`}
      />
      <div className="grow flex gap-4 items-center">
        {showBackButton ? <BackArrow /> : null}
        {header ? (
          <h2 className="text-black text-xl2 font-medium">{header}</h2>
        ) : (
          <div className="flex justify-start">
            <div className="name-badge  w-[21px] h-[21px] bg-[#515151] text-lg leading-5 text-white">
              <span>A</span>
            </div>
            <h6 className="  flex items-center ml-2 text-body-black text-[22px] leading-5">
              Acme
            </h6>
          </div>
        )}
      </div>
      {!isDisableCTI && userDetails && (
        <ReactDropDown
          Options={[
            {
              label: "Logout",
              handleChange: () => {
                dispatch(setPromptDisabled({ isDisabled: false }));
                dispatch(clearToken());
                dispatch(clearConsumerData());
              },
            },
          ]}
          menuHeading={
            <div className="self-end flex gap-4 items-center">
              {userDetails?.total_score !== undefined ? (
                <ScoreBubble
                  {...{
                    score:
                      (userDetails?.request_timestamp
                        ? userDetails?.total_score
                        : 0) ?? 0,
                    inactive: false,
                    risk_level: userDetails?.risk_level ?? "LOW_RISK_CONSUMER",
                    className: " !w-8 !h-8 text-base1 !px-0 !py-0",
                  }}
                />
              ) : (
                <div className="bg-stroke-secondary w-11 h-11 rounded-full flex justify-center items-center">
                  {" "}
                  -{" "}
                </div>
              )}
              <CaretIcon />
            </div>
          }
        />
      )}
    </div>
  );
};
export default SiteHeader;
