// import Cancel from '@/assets/icons/cancel';

import { Listbox } from "@headlessui/react";
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Cancel from "../../assets/icons/cancel";
import { getClassNames, getRandomInt } from "../../utils/helpers";
import ErrorMessage from "./ErrorMessage";
import { Option } from "./types";
interface ReactListboxProps {
  selectedOption?: number | string | number[] | string[];
  onChange?: (value: any) => void;
  Options: Option[];
  name?: string;
  btnClass?: string;
  optionsClass?: string;
  noSelectedMessage?: string;
  label?: string;
  disabled?: boolean;
  labelClass?: string;
  isMulti?: boolean;
}

const ReactListbox = ({
  onChange,
  selectedOption,
  Options,
  name,
  btnClass,
  optionsClass,
  noSelectedMessage,
  label,
  disabled,
  labelClass,
  isMulti,
}: ReactListboxProps) => {
  const [field] = name ? useField(name) : [];
  const formik = name ? useFormikContext() : "";

  const [currentMultiSelectedValue, setCurrentMultiSelectedValue] = useState<
    Option[] | undefined
  >(
    Options?.filter((option: Option) => {
      if (isMulti && typeof selectedOption == "object") {
        return selectedOption?.indexOf(option.value as never) > -1;
      }
      return false;
    })
  );
  const [currentSelectedValue, setCurrentSelectedValue] = useState<
    Option | undefined
  >(Options?.find((option) => option.value == selectedOption));

  useEffect(() => {
    if (name && field) {
      if (isMulti) {
        setCurrentMultiSelectedValue(
          Options?.filter((option: Option) => {
            if (typeof selectedOption == "object") {
              return selectedOption?.indexOf(option.value as never) > -1;
            }
            return false;
          })
        );
      } else {
        setCurrentSelectedValue(
          Options?.find((option) => option.value == field.value)
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      currentSelectedValue?.value &&
      selectedOption !== currentSelectedValue.value
    ) {
      if (onChange) {
        onChange(currentSelectedValue.value);
      } else if (name && field) {
        field?.onChange({
          target: { value: currentSelectedValue.value, name },
        });
      }
    }
  }, [currentSelectedValue]);

  useEffect(() => {
    if (
      isMulti &&
      currentMultiSelectedValue &&
      JSON.stringify(selectedOption) !==
        JSON.stringify(currentMultiSelectedValue.map((data) => data.value))
    ) {
      if (onChange) {
        onChange(currentMultiSelectedValue.map((data) => data.value));
      } else if (name && field) {
        field?.onChange({
          target: {
            value: currentMultiSelectedValue.map((data) => data.value),
            name,
          },
        });
      }
    }
  }, [currentMultiSelectedValue]);

  useEffect(() => {
    if (isMulti) {
      if (typeof selectedOption == "object") {
        setCurrentMultiSelectedValue(
          Options?.filter((option: Option) => {
            return selectedOption?.indexOf(option.value as never) > -1;
          })
        );
      }
    } else if (
      selectedOption &&
      selectedOption !== currentSelectedValue?.value
    ) {
      setCurrentSelectedValue(
        Options?.find((option) => option.value == selectedOption)
      );
    }
  }, [selectedOption]);

  const handleComponentChange = (newStatus: unknown) => {
    if (isMulti) {
      setCurrentMultiSelectedValue(newStatus as Option[]);
    } else {
      setCurrentSelectedValue(newStatus as Option);
    }
  };

  return (
    <Listbox
      value={isMulti ? currentMultiSelectedValue : currentSelectedValue}
      onChange={handleComponentChange}
      disabled={disabled}
      multiple={isMulti}
    >
      {label ? (
        <label
          className={`text-base font-medium leading-5 -tracking-0.2  mb-3 block justify-between ${labelClass}`}
        >
          {label}
        </label>
      ) : (
        ""
      )}

      <div className="relative">
        <Listbox.Button
          className={`relative flex items-center gap-2 text-body-black font-medium rounded-full text-sm1 leading-lg -tracking-0.2 px-2 py-1 border border-solid justify-between border-grey-stroke-primary  ${btnClass ?? ""} ${disabled ? "!text-grey-content-tertiary" : "text-grey-content-secondary"}`}
        >
          {isMulti ? (
            <div className="">
              {currentMultiSelectedValue &&
              currentMultiSelectedValue?.length > 0 ? (
                <ul className=" relative gap-2 flex flex-wrap mx-2 ">
                  {currentMultiSelectedValue.map((option) => (
                    <li
                      key={option.value}
                      className=" rounded-2xl justify-center flex items-center px-2 text-body-black"
                    >
                      {option.label}
                      <button
                        type="button"
                        className="pl-2"
                        onClick={() => {
                          setCurrentMultiSelectedValue(
                            currentMultiSelectedValue.filter(
                              (selectedValues) => {
                                return selectedValues.value != option.value;
                              }
                            )
                          );
                        }}
                      >
                        <Cancel
                          width={8}
                          height={8}
                          className="cursor-pointe"
                          color="#747678"
                        />
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <span>Please select a value</span>
              )}
            </div>
          ) : (
            <span>
              {currentSelectedValue?.label ??
                noSelectedMessage ??
                "Please select a value"}
            </span>
          )}
          {!disabled && <CaretIcon />}
        </Listbox.Button>
        <Listbox.Options
          className={` min-w-32 max-h-72 overflow-auto z-20 bg-white rounded-lg shadow-1 px-1 border border-solid border-stroke-secondary mt-2 absolute top-full left-0 ${optionsClass ?? ""}`}
        >
          {(Options?.length == 0 ||
            currentMultiSelectedValue?.length === Options?.length) && (
            <div className="h-28 flex items-center justify-center">
              <span className="text-base">No Options available !</span>
            </div>
          )}
          {Options.map((option) => {
            return (
              <Listbox.Option
                key={`${option.value}-${getRandomInt()}`}
                className={({ active, selected }) => {
                  return `block my-2 px-2 font-medium text-base leading-5 -tracking-0.2 rounded-md cursor-pointer
                  ${
                    currentMultiSelectedValue &&
                    currentMultiSelectedValue?.findIndex((data) => {
                      return option.value === data.value;
                    }) > -1
                      ? "hidden"
                      : "text-grey-content-secondary"
                  }
                  ${active || selected || currentSelectedValue?.value == option.value ? " bg-stroke-primary" : "text-grey-content-secondary"}`;
                }}
                value={option}
              >
                <div key={option.value}>
                  <span
                    className={getClassNames(
                      "paragraph1 flex py-2 pr-2 w-full"
                    )}
                  >
                    {option.label}
                  </span>
                  {option.description && (
                    <p
                      className={getClassNames(
                        "paragraph1 w-full pb-2 pr-2 text-grey-content-tertiary"
                      )}
                    >
                      {option.description}
                    </p>
                  )}
                </div>
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </div>
      {formik && name ? <ErrorMessage name={name} /> : ""}
    </Listbox>
  );
};

export default ReactListbox;
