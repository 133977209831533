const CONSUMER_TRUST_INDEX = "CONSUMER_TRUST_INDEX";

export { CONSUMER_TRUST_INDEX };

export const PUBLIC_NAVIGATION: { [key: string]: string } = Object.freeze({
  initialise: "/:projectId/sandbox/:projectApiKey",
  consumerInit: "/request-open-banking-consent",
});

export const PRIVATE_NAVIGATION = Object.freeze({
  profile: {
    edit: {
      activeSidebar: 0,
      path: "/",
    },
    bankLogin: {
      sideBarId: 0,
      path: "/bank-login",
    },
    bankConsent: {
      sideBarId: 0,
      path: "/bank-consent",
    },
    consumerView: {
      activeSidebar: 0,
      path: "/consumer-view",
    },
    consentConfirm: {
      activeSidebar: 0,
      path: "/consent-confirm",
    },
    consentConfirmScreen: {
      activeSidebar: 0,
      path: "/consent-confirm-status",
    },
    bankLoginPayment: {
      sideBarId: 0,
      path: "/bank-login-payment",
    },
    consentAuthorization: {
      sideBarId: 0,
      path: "/consent-authorization",
    },
  },
  config: {
    activeSidebar: 1,
    path: "/config",
  },
});

export const IMAGE_SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const ShopperConfigSequence = [
  "IDENTITY",
  "BANKING_HISTORY",
  "OPEN_BANKING",
  "HISTORICAL_DATA",
  "NETWORK_INTELLIGENCE",
  "THIRD_PARTY_DATA",
  "CHARGEBACKS",
  "RETURNS",
  "CLAIMS_OF_ITEMS_NOT_RECEIVED",
  "AUTHORISED_PUSH_PAYMENTS",
  "MULLING",
  "PROMOTION_BONUS_AND_LOYALTY_ABUSE",
  "AFFORDABILITY_AND_PAYMENTS",
  "Identity",
  "Chargebacks",
  "Returns",
  "Claims of Items-not-received",
  "BNPL",
  "Authorised Push Payments",
  "Mulling",
  "Promotion, Bonus & Loyalty Abuse",
  "Affordability and Payments",
];
