import { getClassNames, getRiskLevelColor } from "@/utils/helpers";

const ScoreBubble = ({
  score,
  inactive,
  risk_level,
  className,
}: {
  score: number;
  inactive?: boolean;
  risk_level: string;
  className?: string;
}) => {
  return (
    <div
      className={getClassNames(
        `py-4 px-6 bg-grey text-[3.5rem] flex items-center justify-center w-[110px] h-[110px] rounded-full font-semibold ${className}`,
        score > 0
          ? getRiskLevelColor(risk_level, inactive)
          : "text-grey-content-quaternary"
      )}
    >
      <span>{score > 0 ? score : "-"}</span>
    </div>
  );
};

export default ScoreBubble;
